<template>
    
    <div>

        <div>

            <v-checkbox
                class="ml-4"
                label="Use full width"
                v-model="fullWidth"
                @change="update"
            ></v-checkbox>

        </div>

        <div v-if="loading" class="pa-4 text-center">

            <v-progress-circular 
            :color="app.state.style.primary"
            indeterminate></v-progress-circular>

        </div>
        
        <div v-else-if="image.thumb">
            
            <v-img :src="image.large.public_url"></v-img>
            
            <div class="pa-4">
                    
                <v-btn
                    :color="app.state.style.primary"
                    outlined block
                    @click="remove()"
                >
                    {{ $t('Remove') }}
                </v-btn>
            
            </div>

        </div>

        <div v-else class="text-center pa-4">

            <MediaPopup @selected="setImage" />
        
        </div>

    </div>

</template>

<script>
    
import { inject, ref, onMounted } from '@vue/composition-api' ;
import MediaPopup from '@/components/media/Popup' ;

export default {

    components: {
        MediaPopup
    },
    props: ['component'],
    setup(props){
    
        const app = inject('app') ;
        const loading = ref(false); 

        const image = ref({
            thumb: null,
            original: null,
            large: null,
        });

        const fullWidth = ref(false);
        
        const currentPage = inject('currentPage') ; 

        onMounted(() => {
            getComponent();
        }) ;

        const getComponent = () => {

            loading.value = true ;

            currentPage.getComponent(props.component.uid)
            .onSnapshot((doc) => {

                if (doc.data().fullWidth)
                    fullWidth.value = doc.data().fullWidth;

                loading.value = false ;

                let imageData = {
                    thumb: null,
                    original: null,
                    large: null,
                }; 

                if(doc.data().original != null){
                    imageData.original = doc.data().original ;
                }

                if(doc.data().large != null){
                    imageData.large = doc.data().large ;
                }

                if(doc.data().thumb != null){
                    imageData.thumb = doc.data().thumb ;
                    image.value = imageData ;
                }

            }) ;

        }

        const update = () => {
            const data = {
                fullWidth: fullWidth.value
            }

            currentPage.updateComponent(props.component.uid, data)
            .catch((error) => {
                app.setError(error) ;
            }) ;
        }

        const setImage = (img) => {

            currentPage.updateComponent(props.component.uid, img)
            .then(() => {
                image.value = img ;
            })
            .catch((error) => {
                app.setError(error) ;
            }) ;

        }

        const remove = () => {

            let imageData = {
                thumb: null,
                original: null,
                large: null,
            }; 

            image.value = imageData ;

            currentPage.updateComponent(props.component.uid, imageData)
            .catch((error) => {
                app.setError(error) ;
            }) ;

        }

        return {
            app,
            setImage,
            image,
            remove,
            loading,
            fullWidth,
            update
        }
    
    },

};

</script>