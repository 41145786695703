import { reactive } from '@vue/composition-api' ;
import firebase from 'firebase' ;
import currentWebsite from "@/store/websites/currentWebsite" ;
// import app from "@/store/app" ;

const state = reactive({
});

const get = (args = null) => {

    let query = firebase.firestore().collection("websites")
    .doc(currentWebsite.id()).collection("accommodations")

    if(args?.admin_id != null){
        query = query.where('admin_id', '==', args.admin_id) ;
    }

    if(args?.country != null){
        query = query.where('country', '==', args.country) ;
    }

    if(args?.product_acco_type != null){
        query = query.where('product_acco_type', '==', args.product_acco_type) ;
    }

    return query.get() ;

}

export default {
    state, 
    get
 } ;