<template>
    <div class="pa-4">
        <div v-if="loading" class="pa-4 text-center">

            <v-progress-circular 
            :color="app.state.style.primary"
            indeterminate></v-progress-circular>

        </div>
        <div v-else-if="Object.keys(galleryItems).length > 0">
            <v-row justify-start>
                <Draggable
                    id="galleryDraggable"
                    v-model="galleryItems"
                    @end="updateGalleryOrder"
                >
                    <v-col
                        v-for="(item, i) in galleryItems"
                        :key="i"
                    >
                        <v-card width="225" draggable>
                            <v-card-title>

                                    <v-btn icon @click="deleteGalleryItem(i)">
                                        <v-icon>mdi-delete-outline</v-icon>
                                    </v-btn>

                                    <v-spacer></v-spacer>

                                    <v-icon>mdi-drag-vertical</v-icon>

                                </v-card-title>

                                <v-img
                                    :src="item.large.public_url"
                                    height="200"
                                ></v-img>
                        </v-card>
                    </v-col>
                </Draggable>
            </v-row>
        </div>

        <div class="mt-4">
            <MediaPopup @selected="addGalleryImg" />
        </div>

    </div>
</template>

<style lang="scss" scoped>
#galleryDraggable {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
</style>

<script>
import { ref, inject, onMounted } from '@vue/composition-api';
import MediaPopup from '@/components/media/Popup';
import Draggable from 'vuedraggable';

export default {
    
    components: {
        MediaPopup,
        Draggable
    },

    props: ["component"],

    setup(props) {

        const app = inject("app");
        const currentPage = inject("currentPage");

        const loading = ref(false);

        const galleryItems = ref([]);

        onMounted(() => {
            getComponent();
        });
        
        const getComponent = () => {

            loading.value = true;

            currentPage.getComponent(props.component.uid)
            .onSnapshot((doc) => {
                
                loading.value = false;
                galleryItems.value = [];


                for (let image in doc.data().images) {

                    let currentImage = doc.data().images[image];

                    let imageData = {
                        thumb: null,
                        original: null,
                        large: null,
                    };

                    if (currentImage.original != null){
                        imageData.original = currentImage.original;
                    }

                    if (currentImage.large != null){
                        imageData.large = currentImage.large;
                    }

                    if (currentImage.thumb != null){
                        imageData.thumb = currentImage.thumb;

                        galleryItems.value.push(imageData);
                    }
                }
            });
        }

        const addGalleryImg = (img) => {

            galleryItems.value.push(img);

            let data = {
                images: galleryItems.value
            }

            currentPage.updateComponent(props.component.uid, data)
            .then(() => {
                getComponent();
            })
            .catch((error) => {
                app.setError(error) ;
            });

        }

        const updateGalleryOrder = () => {
            let data = {
                images: galleryItems.value
            }

            currentPage.updateComponent(props.component.uid, data)
            .then(() => {
                getComponent();
            })
            .catch((error) => {
                app.setError(error) ;
            });
        }

        const deleteGalleryItem = (i) => {

            galleryItems.value.splice(i, 1);

            let data = {
                images: galleryItems.value
            }

            currentPage.updateComponent(props.component.uid, data)
            .then(() => {
                getComponent();
            })
            .catch((error) => {
                app.setError(error) ;
            });

        }

        return {
            app,
            addGalleryImg,
            loading,
            galleryItems,
            deleteGalleryItem,
            updateGalleryOrder
        }
    }
}
</script>
