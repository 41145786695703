<template>

    <div v-if="currentPage.state.data">

        <h1 v-if="!changingTitle" @click="changingTitle = true; oldTitle = title;" class="mt-8 ml-8">{{ title }}</h1>

        <div v-else class="d-flex align-center" style="width: fit-content;">
            <v-text-field
                style="width: fit-content; font-size: 30px; font-weight: bold;"
                class="mt-8 ml-8"
                outlined
                autofocus
                :rules="[v => (v || '').length > 2 || 'Title must be longer then 2 characters!']"
                @change="changeTitle"
                v-model="title"
            ></v-text-field>

            <v-btn
                :color="app.state.style.primary"
                class="ml-4 white--text"
                @click="changeTitle"
            >{{ $t('SAVE') }}</v-btn>
            
            <v-btn
                :color="app.state.style.primary"
                outlined
                class="ml-4"
                @click="changingTitle = false"
            >{{ $t('CANCEL') }}</v-btn>
        </div>

        <v-breadcrumbs
        :items="breadcrumbs"
        large class="pt-2 pl-8"
        ></v-breadcrumbs>
        
        <v-card class="pa-4 mx-8 d-flex align-center">

            <a v-if="currentWebsite.getMultiLanguage()" target="_blank" :href="'https://'+currentWebsite.getHost()+'/'+currentPage.getlanguage()+pageUri">
                {{ currentWebsite.getHost() }}/{{ currentPage.getlanguage() }}{{ currentPage.getPath()  }}
            </a>
            <a v-else target="_blank" :href="'https://'+currentWebsite.getHost()+'/'+pageUri">
                {{ currentWebsite.getHost() }}{{ currentPage.getPath()  }}
            </a>

            <v-icon class="ml-1" small>mdi-open-in-new</v-icon>

            <v-spacer></v-spacer>

            <Status />

            <v-menu bottom left v-if="currentPage.state.data.data().language">

                <template v-slot:activator="{ on, attrs }">

                    <v-card
                        v-bind="attrs"
                        v-on="on"
                        elevation="0"
                        class="d-flex align-center pa-2"
                    >   

                        <!-- <v-img :src="$i18n.locale"></v-img> -->
                        <v-img width="30px" height="30px" :src="app.getLanguageImage(currentPage.state.data.data().language)" class="mr-2"></v-img>
                        
                        <v-icon class="ml-1" right>mdi-chevron-down</v-icon>

                    </v-card>

                </template>

                <v-list class="pa-0">

                    <v-list-item-group
                    :color="app.state.style.primary"
                    v-model="currentPage.state.data.data().language"
                    >

                        <v-list-item 
                        :value="currentPage.state.data.data().language"
                        v-for="language in currentWebsite.state.data.selected_languages"
                        :key="language"
                        @click="setLanguage(language)"
                        class="pa-0 px-2">
                            <v-img width="30px" height="30px" :src="app.getLanguageImage(language)" class="mr-2"></v-img>
                            <v-list-item-title>{{ app.getLanguageName(language) }}</v-list-item-title>
                        </v-list-item>

                    </v-list-item-group>

                </v-list>

            </v-menu>

            <Settings />

        </v-card>

        <Edit v-if="currentPage.id()" class="pa-8" />

    </div>

</template>

<script>

import { inject, watch, ref, onMounted } from '@vue/composition-api' ;
import Edit from "@/components/pages/Edit" ;
import Settings from "@/components/pages/Settings" ;
import Status from "@/components/pages/Status" ;
import currentPage from "@/store/pages/currentPage" ;
import pages from "@/store/pages/pages" ;

export default {
    provide: {
        currentPage,
        pages
    },
    components: {
        Edit,
        Settings,
        Status
    },
    setup(props, ctx){
    
        const app = inject('app') ; 
        const currentWebsite = inject('currentWebsite') ; 

        const breadcrumbs = ref([]) ;
        const language = ref(null) ;
        const pageUri = ref(null) ;

        const changingTitle = ref(false);
        const title = ref(null);
        const oldTitle = ref(null);

        const changeTitle = () => {
            changingTitle.value = false;

            if (title.value.length <= 2) {
                title.value = oldTitle.value;
                return app.setError("Title must be longer than 2 characters!");
            }

            currentPage.update({
                name: title.value
            })
            .then(() => {
                init();
                app.setSuccess("Updated title");
            })
            .catch((err) => {
                app.setError(err);
            });
        }

        const getPageUri = async () => {
            pageUri.value = await pages.getUri(currentPage.state.data);
        } ;
        
        const init = () => {
            
            currentPage.get(ctx.root.$route.params.pageId)
            .onSnapshot((doc) => {
                currentPage.set(doc) ;
                title.value = currentPage.getName();
                getPageUri();
            }) 

            currentWebsite.setTitle(ctx.root.$t('Edit')) ;    

        }

        const setLanguage = (lang) => {
            
            currentPage.state.data.data().language = lang ;
            currentPage.update({ language: lang }) ;    
            app.setSuccess(ctx.root.$t('Language updated'));
            // currentPage.get(ctx.root.$route.params.pageId) ;

        }

        watch(() => ctx.root.$route.params.pageId, () => {
            init();
        })

        watch(() => currentPage.state.data, () => {
            
            if(currentPage.getName() != null){
                
                breadcrumbs.value = [
                    {
                        text: ctx.root.$t('Dashboard'),
                        disabled: false,
                        exact: true,
                        to: '/dashboard',
                    },
                    {
                        text: ctx.root.$t('Pages'),
                        disabled: false,
                        exact: true,
                        to: '/pages',
                    },
                    {
                        text: currentPage.getName(),
                        disabled: false,
                        exact: true,
                        to: '/pages/'+currentPage.id(),
                    }
                ] ;

            }

        })

        onMounted(() => {
            init();
        });

        return {
            app,
            init,
            currentPage,
            currentWebsite,
            breadcrumbs,
            language,
            setLanguage,
            pageUri,
            changingTitle,
            title,
            changeTitle,
            oldTitle
        }
    
    },

};

</script>