<template>
    
    <v-dialog
      v-model="dialog"
      width="800"
    >
      <template v-slot:activator="{ on, attrs }">

        <v-btn icon
        v-bind="attrs"
        v-on="on"
        >
            <v-icon>mdi-cog-outline</v-icon>
        </v-btn>
        
      </template>

      <v-card>
          
          <v-card-title class="pr-4 pl-4">

            <div>{{ $t('Settings') }}</div>

            <v-spacer></v-spacer>

            <v-btn icon @click="dialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            
        </v-card-title>

        <v-divider></v-divider>

        <div class="pa-4">

            <v-text-field
                v-model="titleInput"
                :label="$t('Title')"
                counter="60"
                outlined persistent-hint
                :hint="$t('This title is the most important peace of text on your page. It will appear in Google and it needs to summery the contents of your page.')"
            ></v-text-field>

            <v-textarea
                v-model="descriptionInput"
                class="mt-4"
                outlined persistent-hint
                counter="160"
                rows="2"
                :label="$t('Description')"
                :hint="$t('Tell in 160 characters what this page is about. This is also a good place to write down your SEO optimised words.')"
            ></v-textarea>

        </div>

        <v-divider></v-divider>

        <div class="pa-4">

            <v-btn
                :color="app.state.style.primary"
                dark depressed
                :loading="loading"
                @click="updateSettings(); dialog = false"
            >
                {{ $t('Save') }}
            </v-btn>

            <v-btn
                :color="app.state.style.primary"
                outlined class="ml-2"
                :loading="loading" @click="dialog = false"
            >
                {{ $t('Cancel') }}
            </v-btn>

        </div>
        
      </v-card>

    </v-dialog>

</template>

<script>
    
import { inject, ref, onMounted } from '@vue/composition-api' ;

export default {
    
    setup(){
    
        const app = inject('app') ;
        const dialog = ref(false) ;
        const currentPage = inject('currentPage');

        const titleInput = ref(null);
        const descriptionInput = ref(null);

        onMounted(() => {

            currentPage.getPage()
            .onSnapshot((doc) => {
                const data = doc.data();

                if (data.title)
                    titleInput.value = data.title;
                
                if (data.description)
                    descriptionInput.value = data.description;
            });

        });

        const updateSettings = () => {
            const data = {
                title: titleInput.value,
                description: descriptionInput.value
            }

            currentPage.updateSettings(data)
            .then(() => {
                app.setSuccess("Updated settings");
            });
        }
        
        return {
            app,
            dialog,
            titleInput,
            descriptionInput,
            updateSettings
        }
    
    },

};

</script>