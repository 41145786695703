<template>
    
    <div class="pa-4">

        <div>Accommodation filter</div>

        <div>
            <v-switch
                :label="$t('Show Google maps card')"
                v-model="showGoogleMapsCard"
                :color="app.state.style.primary"
                @change="saved = false"
            ></v-switch>
        </div>
        
        <v-btn
            :color="app.state.style.primary"
            dark depressed
            class="mt-4"
            @click="updateComponent()"
            v-if="!saved"
        >
            {{ $t('Save') }}
        </v-btn>

        <div class="mt-4 d-flex align-center" v-else>
            <v-icon left color="green">mdi-check</v-icon>
            {{ $t('Saved') }}
        </div>
        
    </div>

</template>

<script>
    
import { inject, ref, onMounted } from '@vue/composition-api' ;

export default {
    
    props: ['component'],
    setup(props){
    
        const app = inject('app') ;
        const currentPage = inject('currentPage') ; 
        const saved = ref(true) ;

        const showGoogleMapsCard = ref(true);

        onMounted(() => {
            getComponent();
        }) ;

        const getComponent = () => {

            currentPage.getComponent(props.component.uid)
            .onSnapshot(doc => {

                if (doc.data().showGoogleMapsCard)
                    showGoogleMapsCard.value = true;
                else
                    showGoogleMapsCard.value = false;

            }) ;

        }

        const updateComponent = () => {

            saved.value = false ;

            let componentData = {
                "showGoogleMapsCard": showGoogleMapsCard.value
            }

            currentPage.updateComponent(props.component.uid, componentData)
            .then(() => {
                saved.value = true ;
            })
            .catch((error) => {
                app.setError(error) ;
                saved.value = true ;
            }) ;

        }

        return {
            app,
            currentPage,
            getComponent,
            updateComponent,
            saved,
            showGoogleMapsCard
        }
    
    },

};

</script>