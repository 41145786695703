<template>

    <v-menu offset-y>

        <template v-slot:activator="{ on, attrs }">

            <v-btn
            color="grey"
            outlined
            v-bind="attrs"
            v-on="on"
            class="mr-2"
            >

                <v-avatar
                    size="15"
                    :color="statusColor"
                    class="mr-2"
                >
                </v-avatar>

                {{ statusText }}

                <v-icon right>mdi-chevron-down</v-icon>

            </v-btn>

        </template>

        <!-- {{ currentPage.state.data.data().status }} -->

        <v-list class="pa-0">

            <v-list-item @click="update('hidden')">

                <v-list-item-avatar>

                    <v-avatar
                        size="25"
                        color="red"
                    >
                    </v-avatar>

                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>{{ $t('Private') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('Only visible to site admins and editors.') }}</v-list-item-subtitle>
                </v-list-item-content>

            </v-list-item>

            <v-divider></v-divider>

            <v-list-item @click="update('public')">

                <v-list-item-avatar>

                    <v-avatar
                        size="25"
                        color="green"
                    >
                    </v-avatar>

                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>{{ $t('Public') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('Visible to everyone.') }}</v-list-item-subtitle>
                </v-list-item-content>

            </v-list-item>

        </v-list>

    </v-menu>

</template>

<script>

import { inject, ref, computed } from '@vue/composition-api' ;
import currentPage from "@/store/pages/currentPage" ;


export default {
    provide: {
        currentPage
    },

    setup(props, ctx){
    
        const app = inject('app') ; 
        // const currentWebsite = inject('currentWebsite') ; 

        const status = ref([]) ;
        const statusColor = computed(() => {

            if(currentPage.state.data?.data()?.status == null){
                return 'black' ;
            }

            if(currentPage.state.data.data().status == 'hidden'){
                return 'red' ;
            }

            if(currentPage.state.data.data().status == 'public'){
                return 'green' ;
            }

            return 'grey' ;

        }) ;

        const statusText = computed(() => {

            if(currentPage.state.data?.data()?.status == null){
                return ctx.root.$t('Unknown') ;
            }

            if(currentPage.state.data.data().status == 'hidden'){
                return ctx.root.$t('Private') ;
            }

            if(currentPage.state.data.data().status == 'public'){
                return ctx.root.$t('Public') ;
            }

            return ctx.root.$t('Draft') ;

        }) ;

        const update = (status) => {
            
            currentPage.state.data.data().status = status ;
            currentPage.update({status}) ;

        }

        return {
            app,
            status,
            currentPage,
            update,
            statusColor,
            statusText
        }
    
    },

};

</script>