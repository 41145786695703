<template>
    
    <v-dialog
      v-model="dialog"
      width="600"
    >
      <template v-slot:activator="{ on, attrs }">

        <v-btn icon
        v-bind="attrs"
        v-on="on"
        >
            <v-icon>mdi-cog-outline</v-icon>
        </v-btn>
        
      </template>

      <v-card>
          
          <v-card-title class="pr-4 pl-4">

            <div>{{ $t('Settings') }}</div>

            <v-spacer></v-spacer>

            <v-btn icon @click="dialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            
        </v-card-title>

        <v-divider></v-divider>

        <div class="pa-4">

            <v-text-field
                :label="$t('class')"
                counter="60"
                outlined persistent-hint
                :hint="$t('With classes you can apply different styles to a component.')"
                v-model="classData"
            ></v-text-field>

            <v-textarea
                class="mt-4"
                outlined persistent-hint
                counter="160"
                rows="2"
                :label="$t('Style')"
                :hint="$t('Write your own css inline-styles for this component.')"
                v-model="styleData"
            ></v-textarea>

        </div>

        <v-divider></v-divider>

        <div class="pa-4">

            <v-btn
                :color="app.state.style.primary"
                dark depressed
                @click="updateComponent()"
            >
                {{ $t('Save') }}
            </v-btn>

            <v-btn
                :color="app.state.style.primary"
                outlined class="ml-2"
                :loading="loading" @click="dialog = false"
            >
                {{ $t('Cancel') }}
            </v-btn>

        </div>
        
      </v-card>

    </v-dialog>

</template>

<script>
    
import { inject, ref, watch } from '@vue/composition-api' ;

export default {
    props: ['component'],
    setup(props, ctx){
    
        const app = inject('app') ;
        const currentPage = inject('currentPage') ;
        const dialog = ref(false) ;

        const classData = ref(null) ;
        const styleData = ref(null) ;       

        const getComponent = () => {

            currentPage.getComponent(props.component.uid)
            .onSnapshot((doc) => {

                if(doc?.data()?.class != null){
                    classData.value = doc.data().class ;
                }  

                if(doc?.data()?.style != null){
                    styleData.value = doc.data().style ;
                }  
                    
            }) ;

        }

        const updateComponent = () => {

            let componentData = {
                class: classData.value,
                style: styleData.value
            }

            currentPage.updateComponent(props.component.uid, componentData)
            .then(() => {
                app.setSuccess(ctx.root.$t('Updated')) ;
                dialog.value = false ;
            })
            .catch((error) => {
                app.setError(error) ;
            }) ;

        }

        watch(() => dialog.value, () => {

            if(dialog.value){

                getComponent();

            }else{
                classData.value = null;
                styleData.value = null;
            }
            
        })
                
        return {
            app,
            dialog,
            updateComponent,
            classData,
            styleData,
        }
    
    },

};

</script>