<template>
    
    <div class="pa-2 pt-0">

        <v-card class="ma-2 mt-4 pa-4 d-flex align-center"
        v-for="(file, index) in files"
        :key="file.id">

            <div>{{ file.name }}</div>

            <v-spacer></v-spacer>

            <v-btn
                :color="app.state.style.primary"
                outlined
                :href="file.public_url"
                target="_blank"
            >
                {{ $t('Download') }}
            </v-btn>

            <v-btn
                icon
                class="ml-2" @click="remove(index)"
            >
                <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
        
        </v-card>

        <div class="pa-2 mt-4">
            <MediaPopup type="files" @selected="setFile" />
        </div>

    </div>

</template>

<script>
    
import { inject, ref, onMounted } from '@vue/composition-api' ;
import MediaPopup from '@/components/media/Popup';

export default {

    props: ['component'],

    components: {
        MediaPopup,
    },

    setup(props){
    
        const app = inject('app') ;
        const loading = ref(false) ; 

        const files = ref([]);
        
        const currentPage = inject('currentPage') ; 

        onMounted(() => {
            getComponent();
        }) ;

        const getComponent = () => {

            loading.value = true ;

            currentPage.getComponent(props.component.uid)
            .onSnapshot((doc) => {

                if(doc.data().files != null){
                    files.value = doc.data().files ;
                }

                loading.value = false ;

            }) ;

        }

        const setFile = (file) => {

            files.value.push(file);

            const data = {
                files: files.value
            }

            currentPage.updateComponent(props.component.uid, data)
            .catch((error) => {
                app.setError(error) ;
            }) ;

        }

        const remove = (index) => {
            
            files.value.splice(index, 1) ;

            const data = {
                files: files.value
            }

            currentPage.updateComponent(props.component.uid, data)
            .catch((error) => {
                app.setError(error) ;
            }) ;

        }

        return {
            app,
            setFile,
            remove,
            loading,
            files
        }
    
    },

};

</script>