<template>

    <div class="d-flex">

        <v-card style="min-width:300px;">

            <v-list class="pa-0" style="position: sticky; top:8px;">

                <v-list-group
                v-for="category in categories"
                :key="category.key"
                v-model="category.active"
                :prepend-icon="category.action"
                no-action
                >

                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title v-text="category.name"></v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <v-list-item
                        v-for="component in getComponents(category.key)"
                        :key="component.id"
                        draggable 
                        @dragstart="startDrag($event, component); draggingComponent = true;"
                        @dragend="endDrag(); draggingComponent = false;"
                    >
                        <v-list-item-content>

                            <v-list-item-title v-text="component.name"></v-list-item-title>

                        </v-list-item-content>

                        <v-list-item-icon>
                            <v-icon>mdi-drag-vertical</v-icon>
                        </v-list-item-icon>

                    </v-list-item>
                    
                </v-list-group>

            </v-list>

        </v-card>

        <div class="drop-container px-4">

            <div
            v-for="component in allSelectedComponents"
            :key="component.uid">

                <v-card 
                    :draggable="applyDragging"
                    class="ma-2"
                    @drag="draggingComponent = true"
                    @dragstart="startDrag($event, component)"
                    @dragend="endDrag()"
                    v-if="component.type == 'component'"
                >

                    <v-card-title>

                        <div>{{ component.name }}</div>

                        <v-spacer></v-spacer>

                        <v-btn icon @click="removeComponent(component);">
                            <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>

                        <SettingsComponent :component="component" />

                        <v-btn icon @click="up(component);" v-if="getIndex(component.uid) > 0">
                            <v-icon>mdi-chevron-up</v-icon>
                        </v-btn>

                        <v-btn icon @click="down(component);" v-if="(getIndex(component.uid) + 1) < selectedComponents.length">
                            <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>

                        <v-icon
                            @mousedown="applyDragging = true"
                            @mouseleave="applyDragging = false"
                        >
                            mdi-drag-vertical
                        </v-icon>

                    </v-card-title>

                    <v-divider></v-divider>

                    <component :component="component" v-bind:is="component.key"></component>
                                        
                </v-card>

                <div
                    class="drop-zone text-center pa-4 ma-2 my-4"
                    :style="displayDropzone"
                    v-bind:class="{'dragging-over': dragging}"
                    @drop="onDrop($event, component)"
                    @dragover.prevent
                    @dragenter="dragging = true"
                    @dragleave="dragging = false"
                    v-if="component.type == 'dropzone'"
                >
                    {{ $t('Drop your component') }}
                </div>
                
            </div>

        </div>

    </div>

</template>

<style>

/* .drag-item {
    width: 100%;
} */

.drop-zone {
    background-color: rgb(242 242 242);
    border: dashed 2px grey;
    border-radius: 20px;
}

/* .drop-zone:hover {
    background-color: blue;
} */

.drop-container {
    min-width: 768px;
    width: 100%;
}

.dragging-over {
    background-color: rgb(226 226 226);
}

</style>

<script>

import { inject, ref, computed, onMounted } from '@vue/composition-api' ;
import { uuid } from 'vue-uuid'

import HeaderComponent from "@/components/pages/text/Header" ;
import ContentComponent from "@/components/pages/text/Content" ;
import ColumnContent from "@/components/pages/text/ColumnContent";
import IconWithText from "@/components/pages/text/IconWithText";

import SingleImageComponent from "@/components/pages/images/Single" ;
import Carousel from "@/components/pages/images/Carousel" ;
import Gallery from "@/components/pages/images/Gallery";
import WebsiteGallery from "@/components/pages/images/WebsiteGallery";
import ImageWithText from "@/components/pages/images/ImageWithText";
import CardGallery from "@/components/pages/images/CardGallery";

import ManageFiles from "@/components/pages/files/ManageFiles";
import Iframe from "@/components/pages/files/Iframe";

import Filter from "@/components/pages/accommodations/Filter" ;
import MultiFilter from "@/components/pages/accommodations/MultiFilter";
import FilterWebsite from "@/components/pages/accommodations/FilterWebsite";

import MyReservation from "@/components/pages/booking/MyReservation";

import ContactForm from "@/components/pages/communication/ContactForm";

import AllPosts from "@/components/pages/news/AllPosts";

import SettingsComponent from "@/components/pages/SettingsComponent" ;

import SearchAndBook from './booking/SearchAndBook.vue';

export default {
    components: {
        HeaderComponent,
        ContentComponent,
        ColumnContent,
        IconWithText,
        ContactForm,

        SingleImageComponent,
        Carousel,
        Gallery,
        WebsiteGallery,
        ImageWithText,
        CardGallery,

        ManageFiles,
        Iframe,

        Filter,
        MultiFilter,
        FilterWebsite,
        MyReservation,

        AllPosts,

        SettingsComponent,

        SearchAndBook
    },
    setup(props, ctx){
    
        const app = inject('app') ; 
        const currentPage = inject('currentPage') ; 

        const dragging = ref(false) ;
        const applyDragging = ref(false);

        const availableComponents = ref([{
                id: 1 ,
                type: 'component',
                name: ctx.root.$t('Title'),
                key: 'HeaderComponent',
                category: 'text',
            },
            {
                id: 2 ,
                type: 'component',
                name: ctx.root.$t('Content'),
                key: 'ContentComponent',
                category: 'text',
            },
            {
                id: 3 ,
                type: 'component',
                name: ctx.root.$t('Single image'),
                key: 'SingleImageComponent',
                category: 'images',
            },
            {
                id: 4 ,
                type: 'component',
                name: ctx.root.$t('Carousel'),
                key: 'Carousel',   
                category: 'images',         
            },
            {
                id: 5 ,
                type: 'component',
                name: ctx.root.$t('Filter'),
                key: 'FilterWebsite',   
                category: 'accommodations',         
            },
            {
                id: 6 ,
                type: 'component',
                name: ctx.root.$t('Gallery'),
                key: 'WebsiteGallery',   
                category: 'images',         
            },
            {
                id: 7 ,
                type: 'component',
                name: ctx.root.$t('Image with text'),
                key: 'ImageWithText',   
                category: 'images',         
            },
            {
                id: 8,
                type: 'component',
                name: ctx.root.$t('MultiFilter'),
                key: 'MultiFilter',   
                category: 'accommodations', 
            },
            {
                id: 9,
                type: 'component',
                name: ctx.root.$t('Text column'),
                key: 'ColumnContent',
                category: 'text',         
            },
            {
                id: 10,
                type: 'component',
                name: ctx.root.$t('Card gallery'),
                key: 'CardGallery',
                category: 'images',         
            },
            {
                id: 11,
                type: 'component',
                name: ctx.root.$t('Icon with text'),
                key: 'IconWithText',
                category: 'text',         
            },
            {
                id: 12,
                type: 'component',
                name: ctx.root.$t('Contact Form'),
                key: 'ContactForm',
                category: 'communication',         
            },

            {
                id: 13,
                type: 'component',
                name: ctx.root.$t('Interactive map'),
                key: 'InteractiveMap',
                category: 'maps',         
            },

            {
                id: 14,
                type: 'component',
                name: ctx.root.$t('Spacer'),
                key: 'Spacer',
                category: 'text',         
            },

            {
                id: 15,
                type: 'component',
                name: ctx.root.$t('Search & Book'),
                key: 'SearchAndBook',
                category: 'booking',         
            },

            {
                id: 16,
                type: 'component',
                name: ctx.root.$t('My Reservation'),
                key: 'MyReservation',
                category: 'booking',         
            },

            {
                id: 17,
                type: 'component',
                name: ctx.root.$t('File upload'),
                key: 'ManageFiles',
                category: 'files',         
            },

            {
                id: 18,
                type: 'component',
                name: ctx.root.$t('Iframe'),
                key: 'Iframe',
                category: 'files',         
            },

            {
                id: 19,
                type: 'component',
                name: ctx.root.$t('All posts'),
                key: 'AllPosts',
                category: 'news',         
            },

            {
                id: 20,
                type: 'component',
                name: ctx.root.$t('Divider'),
                key: 'Divider',
                category: 'text',         
            },

        ]);

        const categories = ref([
            {
                action: 'mdi-format-text',
                active: true,
                name: 'Text',
                key: 'text'
            },

            {
                action: 'mdi-image-multiple-outline',
                active: false,
                name: 'Images',
                key: 'images'
            },

            // {
            //     action: 'mdi-tent',
            //     active: false,
            //     name: 'Booking',
            //     key: 'booking'
            // },

            {
                action: 'mdi-tent',
                active: false,
                name: ctx.root.$t('Booking'),
                key: 'booking'
            },

            {
                action: 'mdi-home-city-outline',
                active: false,
                name: ctx.root.$t('Accommodations'),
                key: 'accommodations'
            },

            {
                action: 'mdi-phone-outline',
                active: false,
                name: ctx.root.$t('Communication'),
                key: 'communication'
            },

            {
                action: 'mdi-map-marker-outline',
                active: false,
                name: ctx.root.$t('Maps'),
                key: 'maps'
            },

            {
                action: 'mdi-paperclip',
                active: false,
                name: ctx.root.$t('Files'),
                key: 'files'
            },

            {
                action: 'mdi-newspaper',
                active: false,
                name: ctx.root.$t('News'),
                key: 'news'
            },

        ]) ;

        const getComponents = (categoryKey) => {

            return availableComponents.value.filter((availableComponent) => {

                if(availableComponent.category == categoryKey){
                    return availableComponent ;
                }

            });

        }

        const selectedComponents = ref([]) ;

        const allSelectedComponents = computed(() => {
            
            let all = [] ; 

            all.push({
                id: 'start',
                type: 'dropzone',
                parent: null,
                dragHover: false,
            }) ;

            selectedComponents.value.forEach((component) => {
                
                all.push(component) ;

                all.push({
                    id: uuid.v4(),
                    type: 'dropzone',
                    parent: component.uid,
                    dragHover: false,
                }) ;

            });

            return all ;

        });

        const draggingComponent = ref(false);

        const onDrop = (evt, location) => {

            dragging.value = false ; 

            let componentId = evt.dataTransfer.getData('componentId')
            let uuId = evt.dataTransfer.getData('uuId')

            // console.log('event', evt) ;
            // console.log('location', location) ;
            // console.log('componentId drop', componentId) ;
            // console.log('uuId', uuId) ;

            // check if component is already added
            let isSelected = selectedComponents.value.find((component) => {
                
                if(component.uid == uuId){
                    return component ;
                }

            });

            if(isSelected == null){

                let selectedComponent = availableComponents.value.find((component) => {
                
                    // console.log('component.id', componentId) ;

                    if(component.id == componentId){
                        return component ;
                    }

                });

                let index = 0 ;
                let newComponent = Object.assign({}, selectedComponent);

                newComponent.uid = uuId ;

                if(location.id != 'start'){ // if the dropzone is on top of the page, add it as the first item in the array
                    index = getIndex(location.parent) ;
                }

                currentPage.addComponent(newComponent.uid, {
                    component: newComponent
                })
                .then(() => {

                    if(location.id == 'start'){ // if the dropzone is on top of the page, add it as the first item in the array
                        selectedComponents.value.unshift(newComponent) ;
                    }else{
                        selectedComponents.value.splice((index + 1), 0, newComponent) ;
                    }

                    updateRanks();

                })
                .catch((error) => {
                    app.setError(error) ;
                }) ;
                
            }else if(uuId != location.parent){

                
                let componentIndex = selectedComponents.value.findIndex((component) => {
                    
                    if(component.uid == uuId){
                        return component ;
                    }

                });

                selectedComponents.value.splice(componentIndex, 1) ; // remove this component from the array 

                let index = getIndex(location.parent) ;
                
                selectedComponents.value.splice((index + 1), 0, isSelected) ; // add this component to the new spot in the array 

                console.log('isSelected', isSelected) ;
                console.log('componentUid', uuId) ;

                updateRanks();

            }

        }

        const startDrag = (evt, component) => {

            evt.dataTransfer.dropEffect = 'move' ;
            evt.dataTransfer.effectAllowed = 'move' ;
            evt.dataTransfer.setData('componentId', component.id) ;

            if(component.uid == null){
                evt.dataTransfer.setData('uuId', uuid.v4()) ;
            }else{
                evt.dataTransfer.setData('uuId', component.uid) ;
            }
            
            console.log('uuId', component.uid) ;
            console.log('evt', evt) ;
            console.log('componentId', component.id) ;

        }

        const endDrag = () => {
            draggingComponent.value = false;
        }

        const dragenter = (component) => {
            console.log('dragenterrrrr');
            component.dragHover = true ; 
        }
        
        const updateRanks = () => {
            
            selectedComponents.value.forEach((component, index) => {

                currentPage.updateComponent(component.uid, {
                    rank: index
                })
                .catch((error) => {
                    app.setError(error) ;
                }) ;

            });

        }

        const getIndex = (uid) => {

            return selectedComponents.value.findIndex((component) => {
            
                if(component.uid == uid){
                    return component ;
                }

            });

        }

        const removeComponent = (component) => {

            if(confirm(ctx.root.$t('Are you sure?'))){

                // find index
                let index = getIndex(component.uid) ;
                
                currentPage.removeComponent(component.uid)
                .then(() => {
                    console.log('Remove from the arrayyyyy');
                    selectedComponents.value.splice(index, 1) ;
                })
                .catch((error) => {
                    app.setError(error) ;
                }) ;
                
            }

        }

        const up = (component) => {

            let index = getIndex(component.uid) ;

            selectedComponents.value.splice(index, 1) ; // remove this component from the array             
            selectedComponents.value.splice((index - 1), 0, component) ; // add this component to the new spot in the array 

            updateRanks();

        }

        const down = (component) => {

            let index = getIndex(component.uid) ;

            selectedComponents.value.splice(index, 1) ; // remove this component from the array             
            selectedComponents.value.splice((index + 1), 0, component) ; // add this component to the new spot in the array 

            updateRanks();

        }

        const get = () => {

            selectedComponents.value = [] ;

            currentPage.getComponents()
            .then((querySnapshot) => {

                querySnapshot.forEach((doc) => {
                    selectedComponents.value.push(doc.data().component) ; 
                });

                if(
                    ctx.root.$route.query.action != null && 
                    ctx.root.$route.query.action == 'new' &&
                    selectedComponents.value.length == 0
                ){
                    addComponent(1) ;
                }
                
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });

        }

        const addComponent = (componentId) => {

            let selectedComponent = availableComponents.value.find((component) => component.id == componentId);

            let index = 0 ;
            let newComponent = Object.assign({}, selectedComponent);

            newComponent.uid = uuid.v4() ;

            currentPage.addComponent(newComponent.uid, {
                component: newComponent,
                title: currentPage.getName() 
            })
            .then(() => {

                if(location.id == 'start'){ // if the dropzone is on top of the page, add it as the first item in the array
                    selectedComponents.value.unshift(newComponent) ;
                }else{
                    selectedComponents.value.splice((index + 1), 0, newComponent) ;
                }

                updateRanks();

            })
            .catch((error) => {
                app.setError(error) ;
            }) ;

        }

        // watch(() => ctx.root.$route.params.pageId, () => {

        //     console.log("params change");

        //     get();

        // })

        const displayDropzone = computed(() => {
            return {
                display: draggingComponent.value ? 'block' : 'none'
            }
        });

        onMounted(() => {

            console.log("onMountedonMounted");

            get();

        });

        return {
            app,
            onDrop,
            startDrag,
            endDrag,
            selectedComponents,
            availableComponents,
            allSelectedComponents,
            dragging,
            dragenter,
            categories,
            getComponents,
            removeComponent,
            up,
            down,
            getIndex,
            draggingComponent,
            applyDragging,
            displayDropzone
        }
    
    },

};

</script>