<template>
    <div>

        <div id="textColumnDiv" class="d-flex">

            <div class="pa-4">
                <tiptap-vuetify
                    v-model="content0"
                    :extensions="extensions"
                    outlined
                    @input="saved = false"
                />
            </div>

            <div class="pa-4">
                <tiptap-vuetify
                    v-model="content1"
                    :extensions="extensions"
                    outlined
                    @input="saved = false"
                />
            </div>

        </div>

        <div class="pa-4 pt-0">

            <v-btn
                :color="app.state.style.primary"
                dark depressed
                @click="updateComponent()"
                v-if="!saved"
            >
                {{ $t('Save') }}
            </v-btn>

            <div v-else>

                <v-icon left color="green">mdi-check</v-icon>
                {{ $t('Saved') }}

            </div>

        </div>
    </div>
</template>

<style lang="scss" scoped>
#textColumnDiv div {
    max-width: 50rem;
}
</style>

<script>
import { inject, ref, onMounted } from '@vue/composition-api';
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify';

export default {

    components: { TiptapVuetify },

    props: ['component'],

    setup(props, ctx) {
        
        const app = inject('app');
        const currentPage = inject('currentPage');

        const content0 = ref(null);
        const content1 = ref(null);

        const saved = ref(true);

        const extensions = [
            History,
            Blockquote,
            Link,
            Underline,
            Strike,
            Italic,
            ListItem,
            BulletList,
            OrderedList,
            [Heading, {
                options: {
                levels: [1, 2, 3]
                }
            }],
            Bold,
            Code,
            HorizontalRule,
            Paragraph,
            HardBreak
        ];

        onMounted(() => {
            getComponent();
        });

        const getComponent = () => {

            currentPage.getComponent(props.component.uid)
            .onSnapshot((doc) => {

                if(doc.data().content0 != null){
                    content0.value = doc.data().content0;
                }

                if(doc.data().content1 != null){
                    content1.value = doc.data().content1;
                }

            });

        }

        const updateComponent = () => {

            let data = {
                content0: content0.value,
                content1: content1.value
            }

            currentPage.updateComponent(props.component.uid, data)
            .then(() => {
                app.setSuccess(ctx.root.$t('Component updated')) ;
                saved.value = true ;
            })
            .catch((error) => {
                app.setError(error) ;
            }) ;

        }

        return {
            app,
            extensions,
            content0,
            content1,
            saved,
            getComponent,
            updateComponent,
        }
    },
}
</script>