<template>
    <div class="pa-4">
        <v-container class="pa-0">

            <v-row v-if="!loading">
                <v-col v-if="types.length > 0">
                    <v-select
                        :items="types"
                        multiple
                        :label="$t('Accomodation types')"
                        outlined
                        style="max-width: fit-content;"
                        :hint="$t('Only show these types of accommodations (leave empty to show all types).')"
                        persistent-hint
                        @change="saved = false"
                        v-model="items"
                    ></v-select>
                </v-col>

                <v-col v-else>
                    <p>{{ $t('No accommodations found!') }}</p>
                </v-col>
            </v-row>

            <v-row v-else>
                <v-col>
                    <v-progress-linear indeterminate></v-progress-linear>
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col>
                    <v-btn
                        :color="app.state.style.primary"
                        dark depressed
                        class="mt-4"
                        @click="updateComponent()"
                        v-if="!saved"
                    >
                        {{ $t('Save') }}
                    </v-btn>
                    <div class="mt-4 d-flex align-center" v-else>
                        <v-icon left color="green">mdi-check</v-icon>
                        {{ $t('Saved') }}
                    </div>
                </v-col>
            </v-row>

        </v-container>
    </div>
</template>

<script>
import { onMounted, ref, inject } from '@vue/composition-api';
import accommodations from "@/store/accommodations/accommodations" ;

export default {
    provide: {
        accommodations
    },

    props: ['component'],

    setup(props) {
        const app = inject('app');
        const currentPage = inject('currentPage');

        const loading = ref(true);
        const types = ref([]);

        const items = ref(null);

        const saved = ref(true);

        onMounted(() => {
            get();
        });

        const get = () => {
            types.value = [];
            loading.value = true;

            // Get already selected items
            currentPage.getComponent(props.component.uid)
            .onSnapshot(doc => {
                items.value = doc.data().items;
            });

            // Get all accomodation types for dropdown
            accommodations.get()
            .then((querySnapshot) => {

                querySnapshot.forEach((doc) => {
                    types.value.push(doc.data().name);
                });

            }).catch((error) => {
                app.setError(error);
            });

            loading.value = false ;
        }

        const updateComponent = () => {

            const data = {
                items: items.value
            }

            currentPage.updateComponent(props.component.uid, data)
            .then(() => {
                saved.value = true;
            })
            .catch((error) => {
                app.setError(error);
            });

        }

        return {
            app,
            loading,
            types,
            items,
            saved,
            updateComponent
        }
    },
}
</script>