<template>
    <div class="px-4">

        <v-checkbox
            :label="$t('Use custom url')"
            v-model="useCustomUrl"
            @change="saved = false"
        ></v-checkbox>

        <div v-if="useCustomUrl">
        
            <v-text-field
                v-model="customUrl"
                outlined
                :label="$t('Custom url')"
                :hint="$t('Usage: #example, to go to content with the id: example.')"
                :rules="[validationRules.required]"
                @change="saved = false"
            ></v-text-field>

        </div>
        
        <div class="pb-4">
            <v-btn
                :color="app.state.style.primary"
                dark depressed
                @click="updateComponent()"
                v-if="!saved"
            >
                {{ $t('Save') }}
            </v-btn>
            
            <div class="d-flex align-center" v-else>
                <v-icon left color="green">mdi-check</v-icon>
                {{ $t('Saved') }}
            </div>
        </div>

    </div>
</template>

<script>
import { inject, onMounted, ref } from '@vue/composition-api';

export default {

    props: ['component'],

    setup(props, ctx) {

        const app = inject('app');
        const currentPage = inject('currentPage');

        const saved = ref(true);

        const useCustomUrl = ref(false);
        const customUrl = ref('');

        const validationRules = {
            required: value => !!value || ctx.root.$t('This field is required!')
        }

        const getComponent = () => {

            currentPage.getComponent(props.component.uid)
            .onSnapshot((doc) => {

                const data = doc.data();

                if (data?.useCustomUrl) {
                    
                    useCustomUrl.value = true;

                    if (data?.customUrl)
                        customUrl.value = data.customUrl;

                } else {
                    useCustomUrl.value = false;
                    customUrl.value = '';
                }

            }) ;

        }

        const updateComponent = () => {

            const componentData = {
                useCustomUrl: useCustomUrl.value,
                customUrl: useCustomUrl.value ? customUrl.value : ''
            }

            currentPage.updateComponent(props.component.uid, componentData)
            .then(() => {
                app.setSuccess(ctx.root.$t('Component updated')) ;
                saved.value = true ;
            })
            .catch((error) => {
                app.setError(error) ;
            }) ;

        }

        onMounted(() => {
            getComponent();
        });

        return {
            app,
            saved,
            useCustomUrl,
            customUrl,
            validationRules,
            updateComponent
        }

    }

}
</script>