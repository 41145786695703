<template>
    <div class="pa-4">
        
        <div class="d-flex" style="column-gap: 2rem;">

            <div style="width: 25%;">
                <v-text-field
                    outlined
                    dense
                    :label="$t('To:')"
                    :rules="[form.rules.email]"
                    @input="saved = false"
                    v-model="form.toEmail"
                ></v-text-field>
            </div>

            <!-- Checkboxes -->
            <v-checkbox
                dense
                :label="$t('Name field')"
                v-model="form.fields.name"
                :color="app.state.style.primary"
                @change="updateComponent"
            ></v-checkbox>

            <v-checkbox
                dense
                :label="$t('Phone number field')"
                v-model="form.fields.phoneNumber"
                :color="app.state.style.primary"
                @change="updateComponent"
            ></v-checkbox>

            <v-checkbox
                dense
                :label="$t('Address fields')"
                v-model="form.fields.address"
                :color="app.state.style.primary"
                @change="updateComponent"
            ></v-checkbox>

        </div>

        <v-divider class="mb-6"></v-divider>

        <v-container>
            <v-row>
                <v-col cols="max" class="pt-0 pl-0">
                    <v-row>
                        <v-col cols="max">
                            <v-text-field
                                outlined readonly hide-details
                                :label="$t('Email:')"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row v-if="form.fields.name">
                        <v-col cols="max">
                            <v-text-field
                                outlined readonly hide-details
                                :label="$t('Name:')"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row v-if="form.fields.phoneNumber">
                        <v-col cols="max">
                            <v-text-field
                                outlined readonly hide-details
                                :label="$t('Phone number:')"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row v-if="form.fields.address">
                        <v-col>
                            <v-row>
                                <v-col cols="max">
                                    <v-text-field
                                        outlined readonly hide-details
                                        :label="$t('Address:')"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="2">
                                    <v-text-field
                                        outlined readonly hide-details
                                        :label="$t('Nr.')"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="max">
                                    <v-text-field
                                        outlined readonly hide-details
                                        :label="$t('City:')"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="4">
                                    <v-text-field
                                        outlined readonly hide-details
                                        :label="$t('Zipcode:')"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="max">
                                    <v-text-field
                                        outlined readonly hide-details
                                        :label="$t('Country:')"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="max" class="pt-0 pr-0">
                    <v-textarea
                        outlined readonly hide-details no-resize full-width
                        :height="textareaHeight"
                        :label="$t('Message:')"
                    ></v-textarea>
                </v-col>
            </v-row>

        </v-container>

        <v-container class="pl-0 pb-0">

            <v-btn
                :color="app.state.style.primary"
                dark depressed
                @click="updateComponent()"
                v-if="!saved"
            >
                {{ $t('Save') }}
            </v-btn>

            <div v-else>

                <v-icon left color="green">mdi-check</v-icon>
                {{ $t('Saved') }}

            </div>

        </v-container>

    </div>
</template>

<script>
import { ref, inject, onMounted, computed, reactive } from "@vue/composition-api";

export default {

    props: ['component'],

    setup(props, ctx) {

        const app = inject("app");
        const currentPage = inject("currentPage");

        const form = reactive({
            toEmail: '',
            toEmailValid: false,

            fields: {
                name: true,
                phoneNumber: false,
                address: false
            },
            rules: {
                email: (value) => {
                    const validatePattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return validatePattern.test(value) || ctx.root.$t("Invalid e-mail!");
                }
            }
        });

        const saved = ref(true);
        
        const textareaHeight = computed(() => {

            let h = 137; // Default height (2 input fields)
            let i = 0;
            
            if (form.fields.name)
                i++;

            if (form.fields.phoneNumber)
                i++;

            if (form.fields.address)
                i+=3;

            if (i > 1)
                h += 80 * (i - 1);

            return h;

        });

        onMounted(() => {
            getComponent();
        });

        const getComponent = () => {

            currentPage.getComponent(props.component.uid)
            .onSnapshot((doc) => {
                const data = doc.data();

                if (!data.form)
                    updateComponent();

                else {
                    form.toEmail = data.form.to;
                    form.fields.name = data.form.nameFieldBox;
                    form.fields.phoneNumber = data.form.phoneNumberFieldBox;
                    form.fields.address = data.form.addressFieldBox;
                }
            });

        }

        const updateComponent = () => {

            // Validate toEmail
            const validatePattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (validatePattern.test(form.toEmail))
                form.toEmailValid = true;
            else
                form.toEmailValid = false;
            
            let data = {
                form: {
                    to: form.toEmail,
                    toEmailValid: form.toEmailValid,
                    nameFieldBox: form.fields.name,
                    phoneNumberFieldBox: form.fields.phoneNumber,
                    addressFieldBox: form.fields.address
                }
            }

            currentPage.updateComponent(props.component.uid, data)
            .then(() => {
                saved.value = true;
            })
            .catch((error) => {
                app.setError(error);
            });

        }

        return {
            app,
            form,
            saved,
            textareaHeight,

            updateComponent
        }

    },
}
</script>