<template>
    
    <div class="pa-4">

        <v-text-field
            outlined hide-details
            v-model="title"
            @input="saved = false"
        ></v-text-field>

        <v-btn
            :color="app.state.style.primary"
            dark depressed
            class="mt-4"
            @click="updateComponent()"
            v-if="!saved"
        >
            {{ $t('Save') }}
        </v-btn>

        <div class="mt-4 d-flex align-center" v-else>
            <v-icon left color="green">mdi-check</v-icon>
            {{ $t('Saved') }}
        </div>
        
    </div>

</template>

<script>
    
import { inject, ref, onMounted } from '@vue/composition-api' ;

export default {
    
    props: ['component'],
    setup(props){
    
        const app = inject('app') ;
        const currentPage = inject('currentPage') ; 
        const title = ref(null) ;
        const saved = ref(true) ;

        onMounted(() => {
            getComponent();
        }) ;

        const getComponent = () => {

            currentPage.getComponent(props.component.uid)
            .onSnapshot((doc) => {

                if(doc.data().title != null){
                    title.value = doc.data().title ;
                }

            }) ;

        }

        const updateComponent = () => {

            saved.value = false ;

            let componentData = {
                title: title.value
            }

            currentPage.updateComponent(props.component.uid, componentData)
            .then(() => {
                saved.value = true ;
            })
            .catch((error) => {
                app.setError(error) ;
                saved.value = true ;
            }) ;

        }

        return {
            app,
            title,
            currentPage,
            getComponent,
            updateComponent,
            saved
        }
    
    },

};

</script>