var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4 pt-0"},[_c('div',[_c('Draggable',{staticClass:"d-flex justify-start flex-wrap",staticStyle:{"column-gap":"2rem","row-gap":"1rem"},on:{"end":_vm.updateComponent},model:{value:(_vm.cards),callback:function ($$v) {_vm.cards=$$v},expression:"cards"}},_vm._l((_vm.cards),function(card,c){return _c('v-card',{key:c,attrs:{"width":"30%"}},[(!card.imageLoaded)?_c('v-img',{attrs:{"height":"200","src":require("@/assets/img/placeholderImage.png")}}):_c('v-img',{attrs:{"src":card.image.large.public_url,"height":"200"}}),_c('div',{staticClass:"pa-4 pb-0 d-flex justify-space-between align-center"},[_c('MediaPopup',{on:{"selected":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.setImage.apply(void 0, argsArray.concat( [c] ))}}}),_c('div',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteCard(c)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1),_c('v-icon',[_vm._v("mdi-drag-vertical")])],1)],1),_c('div',{staticClass:"pa-4"},[_c('v-text-field',{attrs:{"label":_vm.$t('Title'),"outlined":"","color":_vm.app.state.style.primary,"hide-details":""},on:{"input":function($event){card.contentSaved = false}},model:{value:(card.title),callback:function ($$v) {_vm.$set(card, "title", $$v)},expression:"card.title"}})],1),_c('div',{staticClass:"px-4"},[_c('v-textarea',{attrs:{"outlined":"","name":"input-7-4","label":_vm.$t('Content'),"auto-grow":"","hide-details":""},on:{"input":function($event){card.contentSaved = false}},model:{value:(card.content),callback:function ($$v) {_vm.$set(card, "content", $$v)},expression:"card.content"}})],1),(_vm.checkSelectedPage(c))?_c('v-card',{staticClass:"ma-4 pa-4 d-flex align-center justify-space-between",attrs:{"outlined":""}},[_c('div',[_vm._v(_vm._s(card.link.name))]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteLink(c)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1):_c('div',{staticClass:"pa-4"},[_c('v-text-field',{attrs:{"label":_vm.$t('URL'),"outlined":"","color":_vm.app.state.style.primary,"hide-details":""},on:{"input":function($event){card.contentSaved = false},"change":function($event){return _vm.removeHostFromUrl(c)}},scopedSlots:_vm._u([(card.externalLink)?{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-open-in-new ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("This is an external link")))])])]},proxy:true}:null],null,true),model:{value:(card.url),callback:function ($$v) {_vm.$set(card, "url", $$v)},expression:"card.url"}}),_c('div',{staticClass:"mt-4"},[_c('SelectPage',{on:{"selected":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.setSelectedPage.apply(void 0, argsArray.concat( [c] ))}}})],1)],1),_c('div',{staticClass:"pa-4"},[(!card.contentSaved)?_c('v-btn',{attrs:{"color":_vm.app.state.style.primary,"dark":"","depressed":"","width":"100%"},on:{"click":function($event){return _vm.saveContent(c)}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")]):_c('div',{staticClass:"d-flex justify-start",attrs:{"width":"100%"}},[_c('v-icon',{attrs:{"left":"","color":"green"}},[_vm._v("mdi-check")]),_vm._v(" "+_vm._s(_vm.$t('Saved'))+" ")],1)],1)],1)}),1)],1),_c('v-btn',{staticClass:"mt-4",attrs:{"color":_vm.app.state.style.primary,"outlined":""},on:{"click":_vm.addNewCard}},[_vm._v(" Add Card ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }