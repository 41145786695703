<template>

    <div class="pa-4">

        <tiptap-vuetify
            v-model="content"
            :extensions="extensions"
            outlined
            @input="saved = false"
        />

        <v-btn
            :color="app.state.style.primary"
            dark depressed
            class="mt-4"
            @click="updateComponent()"
            v-if="!saved"
        >
            {{ $t('Save') }}
        </v-btn>

        <div class="mt-4 d-flex align-center" v-else>

            <v-icon left color="green">mdi-check</v-icon>
            {{ $t('Saved') }}
        </div>

    </div>

</template>

<script>

import { inject, ref, onMounted } from '@vue/composition-api' ;
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'

export default {
    components: { TiptapVuetify },
    props: ['component'],
    setup(props, ctx){
    
        const app = inject('app') ;
        const currentPage = inject('currentPage') ; 
        const saved = ref(true) ; 

        const extensions = [
            History,
            Blockquote,
            Link,
            Underline,
            Strike,
            Italic,
            ListItem,
            BulletList,
            OrderedList,
            [Heading, {
                options: {
                levels: [1, 2, 3]
                }
            }],
            Bold,
            Code,
            HorizontalRule,
            Paragraph,
            HardBreak
        ] ;

        const content  = ref(null) ;
 
        onMounted(() => {
            getComponent();
        }) ;

        const getComponent = () => {

            currentPage.getComponent(props.component.uid)
            .onSnapshot((doc) => {

                if(doc.data().content != null){
                    content.value = doc.data().content ;
                }

            }) ;

        }

        const updateComponent = () => {

            console.log('Testeeeenenzow');

            let componentData = {
                content: content.value
            }

            currentPage.updateComponent(props.component.uid, componentData)
            .then(() => {
                app.setSuccess(ctx.root.$t('Component updated')) ;
                saved.value = true ;
            })
            .catch((error) => {
                app.setError(error) ;
            }) ;

        }
        
        return {
            app,
            content,
            extensions,
            updateComponent,
            saved
        }
    
    },

};

</script>