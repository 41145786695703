<template>
    <div class="pa-4">
        
        <div id="alignBar" class="d-flex justify-space-between">

            <div class="d-flex align-bottom">

                <p>{{ $t('Image alignment') }}:</p>

                <v-radio-group
                    v-model="radioButtonsIndex"
                    v-on:change="onRadioChange"
                    row
                    class="pa-0 pl-2 ma-0"
                >
                    <v-radio
                        v-for="(arrow, n) in arrowIcons" :key="n"
                        :value="n + 1"
                        mandatory
                    >
                        <template v-slot:label>
                            <v-icon>mdi-arrow-{{ arrow }}</v-icon>
                            <v-divider
                                class="ml-2"
                                vertical
                                v-if="n < arrowIcons.length - 1"
                            ></v-divider>
                        </template>
                    </v-radio>
                </v-radio-group>

            </div>

        </div>
        
        <div id="imageWithText" class="pt-2 d-flex" :class="imageAlign" style="column-gap: 16px;">

            <div style="min-width: 45%; width: 45%; height: fit-content;">

                <div v-if="!isVideo">
                    <v-img
                        :src="getImageSrc()"
                    >
                        <div v-if="!image.large" style="height: 100%;" class="d-flex align-end">
                            <div class="d-flex justify-space-between align-baseline" style="width: 100%;">
                                <MediaPopup @selected="setImage" />
                                <p>{{ $t('or') }}</p>
                                <div>
                                    <v-text-field
                                        v-model="youtubeUrl"
                                        dense
                                        outlined
                                        :label="$t('Link to video or youtube')"
                                        @change="checkVideo"
                                        :errorMessages="youtubeUrlError"
                                    ></v-text-field>
                                </div>
                            </div>
                        </div>
                    </v-img>

                    <div v-if="image.large">
                        <v-btn
                            icon
                            @click="removeImage"
                        >
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </div>
                </div>

                <div v-else>

                    <iframe
                        v-if="video.type == 'youtube'"
                        :src="`https://www.youtube.com/embed/${video.urlId}`"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        height="250px"
                        width="100%"
                    ></iframe>

                    <video v-else style="width: 100%;" controls>
                        <source :src="video.url" type="video/mp4">
                    </video>

                    <div class="d-flex align-baseline justify-space-between">

                        <v-btn icon @click="removeVideo">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>

                        <div>
                            <v-checkbox
                                v-if="video.type == 'youtube'"
                                v-model="autoplay"
                                @change="updateAutoplay"
                                hide-details
                                :label="$t('Autoplay video')"
                                class="ma-0"
                            ></v-checkbox>
                        </div>

                    </div>

                </div>

            </div>

            <div>
                <v-text-field
                    v-model="title"
                    :label="$t('Title')"
                    outlined
                    :color="app.state.style.primary"
                    hide-details
                    class="mb-4"
                    @input="saved = false"
                ></v-text-field>

                <tiptap-vuetify
                    v-model="content"
                    :extensions="extensions"
                    outlined
                    @input="saved = false"
                />
            </div>

        </div>

        <div class="pt-4 d-flex align-baseline" style="column-gap: 2rem;">
            <div class="d-flex">
                <p>{{ $t('Background color:') }}</p>
                <div
                    class="ml-2 colorPickerDiv"
                    :style="`background:${background.color};`"
                    @click="setColorPickerPos('background', $event)"
                ></div>
            </div>
            
            <v-divider vertical inset style="height: 30px;"></v-divider>

            <div class="d-flex align-baseline">
                <p>{{ $t('Color:') }}</p>

                <div style="width: 125px;">
                    <v-select
                        :label="$t('Thema')"
                        :items="['light', 'dark']"
                        outlined
                        dense
                        hide-details
                        class="ma-0 ml-4"
                        v-model="text.theme"
                        @change="updateColor"
                    ></v-select>
                </div>
            </div>

            <div>
                <v-btn
                    :color="app.state.style.primary"
                    dark depressed outlined
                    @click="clearColors"
                >
                    {{ $t('Reset') }}
                </v-btn>
            </div>

        </div>

        <div
            class="pa-2"
            :style="`z-index: 1; width: fit-content; position: fixed; top:${background.top}px; left:${background.left}px;`"
            v-if="background.shown"
            @mouseleave="background.shown = false"
        >
            <v-color-picker
                dot-size="25"
                mode="hexa"
                v-model="background.color"
                @input="saved = false"
            ></v-color-picker>
        </div>

        <div>
            <v-btn
                :color="app.state.style.primary"
                dark depressed
                class="mt-4"
                @click="updateText(); updateColor()"
                v-if="!saved"
            >
                {{ $t('Save') }}
            </v-btn>

            <div class="d-flex align-center" v-else>

                <v-icon left color="green">mdi-check</v-icon>
                {{ $t('Saved') }}
            </div>
        </div>

    </div>
</template>

<style scoped>
.colorPickerDiv {
    border: 1px solid black;
    height: 25px;
    width: 75px;
    border-radius: 0.5rem;
}
</style>

<script>
import { ref, inject, onMounted } from '@vue/composition-api';
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify';
import MediaPopup from '@/components/media/Popup';

export default {

    components: {
        TiptapVuetify,
        MediaPopup
    },

    props: ['component'],

    setup(props, ctx) {

        //#region Fields

        const app = inject('app') ;
        const currentPage = inject('currentPage');

        const saved = ref(true);
        const extensions = [
            History,
            Blockquote,
            Link,
            Underline,
            Strike,
            Italic,
            ListItem,
            BulletList,
            OrderedList,
            [Heading, {
                options: {
                levels: [1, 2, 3]
                }
            }],
            Bold,
            Code,
            HorizontalRule,
            Paragraph,
            HardBreak
        ];

        const imageLoading = ref(false);
        const image = ref({
            large: null,
            original: null,
            thumb: null
        });

        const title = ref(null);
        const content = ref(null);

        const imageAlign = ref('flex-row');

        const radioButtonsIndex = ref(1);
        const arrowIcons = ref(['left', 'right']);

        const background = ref({
            color: "#fff",
            shown: false,
            top: 0,
            left: 0
        });
        const text = ref({
            theme: 'light'
        });

        const colorSaved = ref(true);

        const isVideo = ref(false);
        const videoId = ref(null);
        const video = ref(null);

        const youtubeUrl = ref(null);
        const youtubeUrlError = ref(null);

        const autoplay = ref(false);

        //#endregion

        onMounted(() => {
            getComponent();
        });

        //#region Methods

        const getComponent = () => {

            imageLoading.value = true;

            currentPage.getComponent(props.component.uid)
            .onSnapshot((doc) => {

                const data = doc.data();

                GetImage(data);

                title.value = data.title;
                content.value = data.content;

                if (data.isVideo) {
                    isVideo.value = data.isVideo;

                    video.value = data.video;

                    // if (data.videoUrlId)
                    //     videoId.value = data.videoUrlId;

                    // if (data.autoplay)
                    //     autoplay.value = data.autoplay;
                }

                imageAlign.value = data.align;

                if (data.radioIndex)
                    radioButtonsIndex.value = data.radioIndex;
                else
                    radioButtonsIndex.value = 1;


                if (data.backgroundColor)
                    background.value.color = data.backgroundColor;
                else
                    background.value.color = "#fff";

                if (data.theme != null)
                    text.value.theme = data.theme ;
                else
                    text.value.theme = "dark";

            });

        }

        const GetImage = (data) => {

            let imageData = {
                large: null,
                original: null,
                thumb: null
            }

            if(data.original != null)
                imageData.original = data.original;

            if(data.large != null)
                imageData.large = data.large;

            if (data.thumb != null) {
                imageData.thumb = data.thumb;
                image.value = imageData;
            }

            imageLoading.value = false;

        }

        const updateAutoplay = () => {

            const data = {
                autoplay: autoplay.value
            }

            currentPage.updateComponent(props.component.uid, data)
            .then(() => {
                app.setSuccess(ctx.root.$t('Updated autoplay'));
            })
            .catch((error) => {
                app.setError(error);
            });

        }

        const updateText = () => {
            
            let data = {
                title: title.value || "",
                content: content.value || ""
            }

            currentPage.updateComponent(props.component.uid, data)
            .then(() => {
                app.setSuccess(ctx.root.$t('Component updated'));
                saved.value = true;
            })
            .catch((error) => {
                app.setError(error);
            });

        }

        const setImage = (img) => {

            currentPage.updateComponent(props.component.uid, img)
            .then(() => {
                image.value = img ;
            })
            .catch((error) => {
                app.setError(error) ;
            }) ;

        }

        const removeImage = () => {
            image.value = {
                large: null,
                original: null,
                thumb: null
            }

            setImage(image.value);
            app.setSuccess(ctx.root.$t('Removed image'));
        }

        const onRadioChange = () => {

            switch (radioButtonsIndex.value) {
                case 1:
                    imageAlign.value = "flex-row";
                    break;
                case 2:
                    imageAlign.value = "flex-row-reverse";
                    break;
            }

            let data = {
                align: imageAlign.value,
                radioIndex: radioButtonsIndex.value
            }

            currentPage.updateComponent(props.component.uid, data)
            .then(() => {
                app.setSuccess(ctx.root.$t('Changed alignment'));
            })
            .catch((err) => {
                console.log(err);
            });

        }

        const getImageSrc = () => {

            if (image.value.large == null)
                return require(`@/assets/img/placeholderImage.png`);

            else
                return image.value.large.public_url;

        }

        const updateColor = () => {

            const data = {
                backgroundColor: background.value.color,
                theme: text.value.theme
            }

            currentPage.updateComponent(props.component.uid, data)
            .then(() => {
                colorSaved.value = true;
                app.setSuccess(ctx.root.$t('Updated colors!'));
            })
            .catch((error) => {
                app.setError(error)
            });

        }

        const setColorPickerPos = (colorPicker, event) => {

            if (colorPicker == 'background') {

                background.value.top = event.clientY - 275;
                background.value.left = event.clientX - 25;
                background.value.shown = true;

            }

        }

        const clearColors = () => {
            background.value.color = "#FFFFFF";
            text.value.theme = "light";

            updateColor();
        }

        const checkVideo = () => {

            youtubeUrlError.value = null;

            // Check for valid url
            const youtubeValidRegex = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
            const match = youtubeUrl.value.match(youtubeValidRegex);

            if (!match) {

                if (isMp4Video(youtubeUrl.value))
                    return setVideo(youtubeUrl.value, 'mp4');
                else
                    return youtubeUrlError.value = ctx.root.$t("Invalid video url");
                
            }

            setVideo(match[1], 'youtube');
        }

        const isMp4Video = (url) => {

            const mp4ValidationRegex = /https?.*?\.mp4/;
            return url.match(mp4ValidationRegex);

        }

        const setVideo = (id, videoType) => {

            const data = {
                isVideo: true
            }

            if (videoType == 'youtube') {
                
                data['video'] = {
                    urlId: id,
                    type: 'youtube'
                }

            } else {

                data['video'] = {
                    url: id,
                    type: 'mp4'
                }

            }

            currentPage.updateComponent(props.component.uid, data)
            .then(() => {
                app.setSuccess(ctx.root.$t('Added video'));
            })
            .catch((error) => {
                app.setError(error) ;
            }) ;

        }

        const removeVideo = () => {

            const data = {
                isVideo: false,
                video: null
            }

            currentPage.updateComponent(props.component.uid, data)
            .then(() => {
                app.setSuccess(ctx.root.$t('Removed video'));
                isVideo.value = false;
                youtubeUrl.value = null;
            })
            .catch((error) => {
                app.setError(error) ;
            }) ;

        }

        //#endregion

        return {
            // Fields
            app,
            imageLoading,
            image,
            title,
            content,
            imageAlign,
            radioButtonsIndex,
            background,
            text,
            arrowIcons,
            saved,
            extensions,
            colorSaved,
            youtubeUrl,
            youtubeUrlError,
            isVideo,
            videoId,
            autoplay,
            video,

            // Methods
            updateText,
            setImage,
            removeImage,
            onRadioChange,
            getImageSrc,
            setColorPickerPos,
            updateColor,
            clearColors,
            checkVideo,
            removeVideo,
            updateAutoplay
        }
    }
}
</script>
