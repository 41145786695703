<template>
    <v-container>

        <v-row align="baseline">
            <v-col cols="6">
                <v-text-field
                    v-model="pageLink"
                    color="primary"
                    outlined
                    :label="$t('Link to page')"
                    @input="saved = false"
                ></v-text-field>
            </v-col>

            <v-col>
                <v-btn
                    :color="app.state.style.primary"
                    dark depressed
                    @click="update()"
                    v-if="!saved"
                >
                    {{ $t('Save') }}
                </v-btn>
            </v-col>
        </v-row>

        <v-row v-if="pageLink">
            <v-col cols="12" style="min-height: 500px;">
                <iframe :src="pageLink" frameborder="0" width="100%" height="100%"></iframe>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import { ref, inject, onMounted } from '@vue/composition-api';

export default {
    props: ['component'],

    setup(props, ctx) {
        const pageLink = ref(null);

        const saved = ref(true);

        const app = inject('app');
        const currentPage = inject('currentPage');

        const get = () => {
            currentPage.getComponent(props.component.uid)
            .onSnapshot((doc) => {

                if (doc.data().pageLink)
                    pageLink.value = doc.data().pageLink;

            });
        }

        const update = () => {
            const data = {
                pageLink: pageLink.value
            }

            currentPage.updateComponent(props.component.uid, data)
            .then(() => {
                saved.value = true;
                app.setSuccess(ctx.root.$t('Saved Iframe'));
            })
            .catch((error) => {
                app.setError(error);
            });
        }

        onMounted(() => {
            get();
        });

        return {
            app,
            pageLink,
            saved,
            update
        }
    },
}
</script>