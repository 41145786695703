<template>
    <div class="pa-4">

        <div class="d-flex align-center" style="column-gap: 1rem;">
            <div>
                <v-btn
                    :color="app.state.style.primary"
                    outlined
                    @click="addIcon()"
                >
                    {{ $t('Add icon') }}
                </v-btn>
            </div>

            <div>
                <v-checkbox
                    hide-details
                    :label="$t('Use full page width')"
                    class="ma-0 pa-0"
                    v-model="fullWidth"
                    @change="updateWidth"
                ></v-checkbox>
            </div>
        </div>

        <div class="mt-4">

            <div>

                <Draggable
                    class="d-flex justify-start flex-wrap"
                    style="gap: 2rem;"
                    v-model="icons"
                    @end="updateComponent"
                >

                    <v-card
                        v-for="(iconCard, i) in icons" :key="i"
                        style="width: 31%"
                        class="pa-4"
                    >
                        
                        <v-combobox
                            label="Icon"
                            outlined
                            dense
                            :items="getIconList"
                            v-model="iconCard.icon"
                            @input="saved = false"
                        >

                            <template v-slot:item="{ item }">
                                <div class="d-flex align-center">
                                    <v-icon class="mr-4">mdi-{{ item }}</v-icon>
                                    <p class="ma-0">{{ item }}</p>
                                </div>
                            </template>

                            <template v-slot:prepend>
                                <v-icon>mdi-{{ iconCard.icon }}</v-icon>
                            </template>
                            <template v-slot:append-outer>
                                <div class="d-flex justify-end">
                                    <v-icon>mdi-drag-vertical</v-icon>
                                    <v-icon @click="deleteIcon(i)">mdi-delete</v-icon>
                                </div>
                            </template>
                        </v-combobox>

                        <v-text-field
                            :label="$t('Title')"
                            outlined
                            dense
                            v-model="iconCard.title"
                            @input="saved = false"
                        ></v-text-field>

                        <v-textarea
                            outlined
                            no-resize
                            hide-details
                            :label="$t('Description')"
                            v-model="iconCard.description"
                            @input="saved = false"
                        ></v-textarea>

                    </v-card>

                </Draggable>

                <div class="d-flex mt-4">
                    <div style="width: 150px;" class="mr-4">
                        <v-btn
                            :color="app.state.style.primary"
                            dark depressed
                            v-if="!saved"
                            @click="updateComponent"
                            width="100%"
                        >
                            {{ $t('Save') }}
                        </v-btn>

                        <div width="100%" class="d-flex justify-start" v-else>

                            <v-icon left color="green">mdi-check</v-icon>
                            {{ $t('Saved') }}

                        </div>
                    </div>

                </div>
            </div>

        </div>        

    </div>
</template>

<script>
import { ref, inject, onMounted } from "@vue/composition-api";
import Draggable from 'vuedraggable';

export default {

    components: {
        Draggable
    },

    props: ['component'],

    setup(props) {

        const app = inject("app");
        const currentPage = inject('currentPage');

        const icons = ref([]);

        const saved = ref(true);

        const fullWidth = ref(false);

        const getIconList = ref(['account', 'calendar', 'car', 'pool', 'map-marker']);

        onMounted(() => {
            getComponent();
        });

        const addIcon = () => {
            
            icons.value.push({
                icon: "help",
                title: "",
                description: ""
            });
            updateComponent();

        }

        const getComponent = () => {

            currentPage.getComponent(props.component.uid)
            .onSnapshot((doc) => {
                const data = doc.data();

                icons.value = [];
                const iconCards = data.iconCards;

                if (data.fullWidth)
                    fullWidth.value = data.fullWidth;

                for (let i in iconCards) {

                    let card = iconCards[i];

                    icons.value.push(card);

                }
            
            });

        }

        const updateComponent = () => {

            const data = {
                iconCards: icons.value
            }

            currentPage.updateComponent(props.component.uid, data)
            .then(() => {
                saved.value = true;
                getComponent();
            })
            .catch((error) => {
                app.setError(error) ;
            });

        }

        const updateWidth = () => {

            let fullWidthCheck = false;
            if (fullWidth.value)
                fullWidthCheck = fullWidth.value;

            
            const data = {
                fullWidth: fullWidthCheck
            }

            currentPage.updateComponent(props.component.uid, data)
            .catch((error) => {
                app.setError(error) ;
            });

        }

        const deleteIcon = (index) => {

            icons.value.splice(index, 1);
            updateComponent();

        }

        return {
            app,
            icons,
            saved,
            fullWidth,
            getIconList,

            addIcon,
            updateComponent,
            updateWidth,
            deleteIcon
        }

    }

}
</script>