<template>
    <div class="pa-4 pt-0">

        <div>
            <Draggable
                class="d-flex justify-start flex-wrap"
                style="column-gap: 2rem; row-gap: 1rem;"
                v-model="cards"
                @end="updateComponent"
            >
                <v-card
                    width="30%"

                    v-for="(card, c) in cards" :key="c"
                >

                    <v-img
                        v-if="!card.imageLoaded"
                        height="200"
                        :src="require(`@/assets/img/placeholderImage.png`)"
                    ></v-img>

                    <v-img
                        v-else
                        :src="card.image.large.public_url"
                        height="200"
                    ></v-img>

                    <div class="pa-4 pb-0 d-flex justify-space-between align-center">

                        <MediaPopup @selected="setImage(...arguments, c)" />

                        <div>
                            <v-btn
                                icon
                                @click="deleteCard(c)"
                            >
                                <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>

                            <v-icon>mdi-drag-vertical</v-icon>
                        </div>
                    </div>

                    <div class="pa-4">
                        <v-text-field
                            :label="$t('Title')"
                            outlined
                            :color="app.state.style.primary"
                            hide-details
                            v-model="card.title"
                            @input="card.contentSaved = false"
                        ></v-text-field>
                    </div>

                    <div class="px-4">
                        <v-textarea
                            outlined
                            name="input-7-4"
                            :label="$t('Content')"
                            auto-grow
                            v-model="card.content"
                            hide-details
                            @input="card.contentSaved = false"
                        ></v-textarea>
                    </div>

                    <v-card
                        v-if="checkSelectedPage(c)"
                        outlined
                        class="ma-4 pa-4 d-flex align-center justify-space-between"
                    >

                        <div>{{ card.link.name }}</div>

                        <v-btn
                            icon
                            @click="deleteLink(c)"
                        >
                            <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>

                    </v-card>

                    <div v-else class="pa-4">

                        <v-text-field
                            :label="$t('URL')"
                            outlined
                            :color="app.state.style.primary"
                            hide-details
                            v-model="card.url"
                            @input="card.contentSaved = false"
                            @change="removeHostFromUrl(c)"
                        >
                            <template v-if="card.externalLink" v-slot:append>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            mdi-open-in-new
                                        </v-icon>
                                    </template>
                                    <span>{{ $t("This is an external link") }}</span>
                                </v-tooltip>
                            </template>

                        </v-text-field>

                        <div class="mt-4">
                            <SelectPage @selected="setSelectedPage(...arguments, c)" />
                        </div>

                    </div>

                    <div class="pa-4">
                        <v-btn
                            :color="app.state.style.primary"
                            dark depressed
                            @click="saveContent(c)"
                            v-if="!card.contentSaved"
                            width="100%"
                        >
                            {{ $t('Save') }}
                        </v-btn>

                        <div width="100%" class="d-flex justify-start" v-else>

                            <v-icon left color="green">mdi-check</v-icon>
                            {{ $t('Saved') }}

                        </div>
                    </div>
                </v-card>
            </Draggable>
        </div>

        <v-btn
            class="mt-4"
            :color="app.state.style.primary"
            outlined
            @click="addNewCard"
        >
            Add Card
        </v-btn>

    </div>
</template>

<script>
import { ref, onMounted, inject } from '@vue/composition-api';
import MediaPopup from '@/components/media/Popup';
import Draggable from 'vuedraggable';
import SelectPage from "@/components/pages/Select" ;
import { uuid } from 'vue-uuid';
import pages from "@/store/pages/pages" ;

export default {
    components: { MediaPopup, Draggable, SelectPage },

    props: ['component'],

    provide: {
        pages
    },

    setup(props) {

        //#region Fields

        const app = inject("app");
        const currentPage = inject('currentPage');
        const currentWebsite = inject('currentWebsite');

        const imageSelected = ref(false);
        const image = ref(null);

        const cards = ref([]);
        const selectedPage = ref(null);

        //#endregion

        onMounted(() => {
            getComponent();
        });

        //#region Methods

        const getComponent = () => {
            
            currentPage.getComponent(props.component.uid)
            .onSnapshot((doc) => {

                const data = doc.data();

                cards.value = [];
                const cardData = data.cards;

                for (let i in cardData) {

                    let card = cardData[i];

                    if (card.link)
                        cards.value.push(cardData[i]);

                    else {
                        card.link = {
                            path: "none"
                        }
                        cards.value.push(card);
                    }

                }

            });

        }

        const addNewCard = () => {
            cards.value.push(
                {
                    imageLoaded: false,
                    image: null,
                    contentSaved: true,
                    title: null,
                    url: null,
                    content: null
                }
            );
            updateComponent();
        }

        const setImage = (img, arrayIndex) => {
            
            cards.value[arrayIndex].image = img;
            cards.value[arrayIndex].imageLoaded = true;
            updateComponent();

        }

        const updateComponent = () => {
            
            const data = {
                cards: cards.value
            }

            currentPage.updateComponent(props.component.uid, data)
            .then(() => {
                getComponent();
            })
            .catch((error) => {
                app.setError(error) ;
            });

        }

        const removeHostFromUrl = (arrayIndex) => {

            let card = cards.value[arrayIndex];

            if (card.url[0] == '/')
                return cards.value[arrayIndex].externalLink = false;

            if (card.url.split('.')[0] == 'www' || card.url.split('.')[0] == 'WWW') {
                card.url = 'http://' + card.url;
                return cards.value[arrayIndex].externalLink = true;
            }
            

            let split = card.url.replace(/(^\w+:|^)\/\//, '').split('/');

            if (currentWebsite.state.data.hosts.indexOf(split[0]) > -1) {
                split.shift();

                split = split.join('/');

                cards.value[arrayIndex].externalLink = false;

                return card.url = '/' + split;
            }

            cards.value[arrayIndex].externalLink = true;

        }

        const saveContent = (arrayIndex) => {
            
            cards.value[arrayIndex].contentSaved = true;
            updateComponent();

        }
        
        const deleteCard = (arrayIndex) => {

            cards.value.splice(arrayIndex, 1);
            updateComponent();

        }

        const setSelectedPage = async (page, index) => {

            let to = await pages.getUri(page);

            const cardLink = {
                id: uuid.v4(),
                page_id: page.id,
                name: page.data().name,
                path: to
            }

            cards.value[index].link = cardLink;
            updateComponent();

        }

        const checkSelectedPage = (index) => {
            
            if (cards.value[index].link.path != 'none')
                return true;
            
            return false;

        }

        const deleteLink = (index) => {
            cards.value[index].link = {
                path: 'none'
            }

            updateComponent();
        }

        //#endregion

        return {
            app,
            imageSelected,
            image,
            cards,
            selectedPage,
            setImage,
            updateComponent,
            addNewCard,
            deleteCard,
            saveContent,
            setSelectedPage,
            checkSelectedPage,
            deleteLink,
            removeHostFromUrl
        }

    },
}
</script>